import { MenuCreateOptionInterface } from 'kernel/types/module'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
          label: this.$t('page.site-admin'),
          icon: 'fa fa-user-cog',
          group: [
            {
              label: this.$t('page.site-admin'),
              route: {
                name: 'site-admin',
              },
            },
            {
              label: this.vm.$t('page.site-admin-group'),
              route: {
                name: 'site-admin-group',
              },
            },
            {
              label: this.vm.$t('page.site-admin-log'),
              create: (options: MenuCreateOptionInterface ) =>
                window.eagleLodash.get(options.application, 'siteAdminLog') === true,
              icon: 'fa fa-info-circle',
              route: {
                name: 'site-admin-log',
              },
            },
          ],
        },
      ],
    }
  }
}

export default () => (new menu()).get()
